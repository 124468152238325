@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/static/pretendard.min.css");

#app-root {
  display: flex;
  justify-content: center;
  flex-direction: row;

  --anim-duration: 0.2s;
}

.App {
  display: flex;
  flex-direction: column;
}

.body-container {
  transition: padding var(--anim-duration) ease;
}

.mini-cards-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex-wrap: wrap;
}

.card {
  padding: 16px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  min-width: 48px;
  min-height: 32px;
  border-radius: 8px;
  height: min-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mini-card {
  /*max-width: 480px;*/
}

.vertical-list {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 16px;
  /*flex-direction: column;*/
}

.vertical-list * {
  flex-shrink: 0;
}

.total-card {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.unselectable {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
